import _ from "lodash";
import { polyfill } from "es6-promise";
import { createApp } from "vue";
import { createGtm } from "@gtm-support/vue-gtm";
import * as Sentry from "@sentry/vue";

import "../src/pusher";

import env from "./env";
import config from "./config";
import "./yup/yupCustomRules";
import i18n from "./i18n";
import pinia from "./stores";
import router from "./routes";
import vuetify from "./vuetify";
import directives from "./directives";
import App from "./App.vue";
import GTMService from "@/services/GTMService";

polyfill();

window._ = _;

const app = createApp(App);

if ("production" === import.meta.env.MODE) {
	Sentry.init({
		app,
		dsn: "https://e6c7654b8dce49e06d023bd879303f8e@o4507300192321536.ingest.de.sentry.io/4507305391095889",
		integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration(), Sentry.captureConsoleIntegration()],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,

		// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: [
			"localhost",
			/^https:\/\/files\.stargres\.pl\/api/,
			/^https:\/\/files-dev\.stargres\.pl\/api/,
			/^https:\/\/files-stg\.stargres\.pl\/api/,
		],

		// Capture Replay for 10% of all sessions,
		// plus for 100% of sessions with an error
		replaysSessionSampleRate: 0.0,
		replaysOnErrorSampleRate: 1.0,
	});
}

app.config.globalProperties.$env = env;
app.config.globalProperties.$config = config;
app.config.globalProperties.$baseUrl = env.BASE_URL;
app.use(i18n);
app.use(pinia);
app.use(router);
Object.keys(directives).forEach((name) => {
	app.directive(name, directives[name]);
});
app.use(vuetify);

if ("production" === import.meta.env.MODE) {
	app.use(
		createGtm({
			id: GTMService.gtm.id,
			vueRouter: router,
		})
	);
}

app.mount("#app");
